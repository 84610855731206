export const OurAdventagesData = [
    [
        {
            heading: "Co-packing Activities",
            para: "Our co-packing activities in the warehouse include labeling, kitting, repackaging, and more to enhance your products and optimize your logistics."
        },
        {
            heading: "Cross Dock & Transloading",
            para: "Our strategic cross-dock and transloading services offer seamless freight transfers, optimizing your supply chain and reducing handling costs."
        },
        {
            heading: "Transport Distribution",
            para: "From order fulfillment to final mile delivery, we optimize every step of the process, ensuring timely and efficient product delivery."
        }
    ],
    [
        {
            heading: "Last Miles Delivery",
            para: "Our last-mile delivery service is the final touch that ensures your products reach their destination efficiently and on time."
        },
        {
            heading: "Freight Services",
            para: "Enhance your logistics and reduce costs with our comprehensive freight services."
        },
        {
            heading: "Clearance",
            para: "We ensure a smooth and compliant process, saving you time and avoiding customs-related delays."
        }
    ],
]