// import img1 from '../../../assets/img/logistic/Pick-Pack-Ship.jpg'
// import img2 from '../../../assets/img/logistic/close-up-scanning-box.jpg'
// import img3 from '../../../assets/img/logistic/industrial-forklift-machine-lifting-palette-full-cardboard-boxes-placing-them-shelves-distribution-warehouse-facility.jpg'

// import img4 from '../../../assets/img/logistic/5A410955-38D2-4FDD-8D93-599ADA133108-e1524301880393.jpeg'
// import img5 from '../../../assets/img/logistic/Header-6-key-facts-about-LCL-shipping-scaled.jpeg'
// import img6 from '../../../assets/img/logistic/cartons-on-pallets-shipping-container-loading.jpeg'

// import img7 from '../../../assets/img/logistic/critical-freight-services.jpeg'
// import img8 from '../../../assets/img/logistic/0812-1024x683.jpg'
// import img9 from '../../../assets/img/logistic/prokect-cargo.jpg'

export const LogisticsData = [
    [
        {
            img: "https://ik.imagekit.io/tvvljykxu/ULD%20WEB/logistic/Pick-Pack-Ship.jpg?updatedAt=1698326417757",
            heading: "Pick & Pack Services",
            para: "Need access to a specific piece of inventory? Simply order a unit and we’ll locate, label, package and send it to you via parcel service provider."
        },
        {
            img: "https://ik.imagekit.io/tvvljykxu/ULD%20WEB/logistic/close-up-scanning-box.jpg?updatedAt=1698326516634",
            heading: "Labelling & Scanning",
            para: "When a container load arrives unordered, we’ll sort and label it in our warehouse before scanning each item into our IT system for easier management."
        },
        {
            img: "https://ik.imagekit.io/tvvljykxu/ULD%20WEB/logistic/industrial-forklift-machine-lifting-palette-full-cardboard-boxes-placing-them-shelves-distribution-warehouse-facility.jpg?updatedAt=1698326452431",
            heading: "Long or Short-Term Storage",
            para: "We offer short-term or long-term storage at our facilities. Your goods will be easily retrievable at any given time. We want to meet your exact needs and offer flexible solutions for any customer."
        }
    ],
    [
       
        {
            img: "https://ik.imagekit.io/tvvljykxu/ULD%20WEB/logistic/5A410955-38D2-4FDD-8D93-599ADA133108-e1524301880393.jpeg?updatedAt=1698140810556",
            heading: "Full Container Load (FCL)",
            para: "We offer  flexible when routing full containers (20’, 40’ or 40’HQ) and offers lots of possibilities with carriers. Fixed allocations and volume agreements with many carriers enable guaranteed space for your goods."
        },
        {
            img: "https://ik.imagekit.io/tvvljykxu/ULD%20WEB/logistic/Header-6-key-facts-about-LCL-shipping-scaled.jpg?updatedAt=1698326483790",
            heading: "Regular Consolidation Service (LCL)",
            para: "Enjoy big savings for shipments that don’t fill an entire container (or that can be efficiently distributed among multiple loads): We calculate costs based on the space an individual load takes and ensure weekly departures of consolidated containers from 70 Asian origins."
        },
        {
            img: "https://ik.imagekit.io/tvvljykxu/ULD%20WEB/logistic/cartons-on-pallets-shipping-container-loading.jpeg?updatedAt=1698140810913",
            heading: "Buyer’s Consolidation (LCL/FCL)",
            para: "If you have many different suppliers in one area, we're able to bundle your freight shipments into one container, resulting in lower shipping rates than if you were sending goods separately."
        }
    ]
    , [
        {
            img: "https://ik.imagekit.io/tvvljykxu/ULD%20WEB/logistic/critical-freight-services.jpeg?updatedAt=1698140812855",
            heading: "Economy, Direct & Express Service",
            para: "Depending on the urgency of your shipment, we can find solutions with the lowest price, the most direct route, or the fastest transport."
        },
        {
            img: "https://ik.imagekit.io/tvvljykxu/ULD%20WEB/logistic/0812-1024x683.jpg?updatedAt=1698140810527",
            heading: "Individual Transit Times and Solutions",
            para: "Our services fit all needs and timeframes: Consolidation, Direct Service, Express Service, Sea & Air Solution, Charter Solution, Onboard courier, Same Day Service."
        },
        {
            img: "https://ik.imagekit.io/tvvljykxu/ULD%20WEB/logistic/prokect-cargo.jpg?updatedAt=1698140814290",
            heading: "Suitable for Special Freight",
            para: "We offer transport of perishable, dangerous and valuable goods via the air. It can include jewellery, fruits, flowers, or meat."
        }
        
    ]
]