import React from 'react'
import HomeBanner from '../component/Home_One/Banner'
import LogisticsService from '../component/Home_One/Logistics_Services'
import HomeAbout from '../component/Home_One/About'
import OurAdvantage from '../component/Home_One/Our_Advantages'
import MapArea from '../component/Home_One/Map'


const index = () => {
    return (
        <>
            <HomeBanner/>
            <LogisticsService/>
            <HomeAbout/>
            <OurAdvantage/>
            <MapArea/>
        </>
    )
}

export default index
