import React from 'react'
// import {Link} from 'react-router-dom';

// ServiceCard Area
const ServiceCard = props => {
    // const value = `/service_details/${props.slug}`
    // console.log(value);
    return (
        <>
            <div className="service-card">
                <img src={props.img} alt="image_service"/>
                <div className="service-caption rounded">
                    <h3>{props.heading}</h3>
                    <p>{props.para}</p>
                    {/* <Link to={value} className="read_more_btn">{props.button}<i
                        className="fas fa-long-arrow-alt-right"></i></Link> */}
                </div>
            </div>

        </>
    )
}

export default ServiceCard
