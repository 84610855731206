import React from 'react'


const HomeAbout = () => {
    return (
        <>
            <section id="home_about_area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about_img">
                                <img className="rounded" src={"https://ik.imagekit.io/tvvljykxu/ULD%20WEB/home1/warehous.jpeg?updatedAt=1698322560032"} alt="About_Img"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about_content">
                                <div className="heading-left-border">
                                    <h2>Warehousing And Distribution</h2>
                                </div>
                                <h3>All our efforts and investments are geared towards offering better solutions.</h3>
                                <p>ULD Malaysia was established in the year 2022 and under the member of IFB group, we have over 70 own offices situated in Far East, South East Asia, India Sub-continental and Europe. 
                                    We are an international logistics and freight provider specializing in air and ocean freight services, fully licensed with well developed network worldwide. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeAbout