import React from 'react'
import CommonBanner from '../component/Common/Banner'
import HomeTwoAbout from '../component/Home_Two/About'
import ServiceHomeTwo from '../component/Home_Two/Service'


const About = () => {
    return (
        <>
            <CommonBanner heading="About" page="About"/>
            <HomeTwoAbout/>
            <ServiceHomeTwo/>

        </>
    )
}

export default About
