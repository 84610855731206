// import img1 from '../../../assets/img/service/warehous.jpg'
// import img2 from '../../../assets/img/service/international-container-cargo-ship-harborfreight-transportation-shipping-ai-generative.jpg'
// import img3 from '../../../assets/img/service/critical-freight-services.jpeg'
// import img4 from '../../../assets/img/service/serv-4.jpg'
// import img5 from '../../../assets/img/service/serv-5.jpg'
// import img6 from '../../../assets/img/service/serv-6.jpg'
// import img7 from '../../../assets/img/service/serv-7.jpg'
// import img8 from '../../../assets/img/service/serv-8.jpg'

export const ServiceData = [
    {
        slug: "p-1",
        img: "https://ik.imagekit.io/tvvljykxu/ULD%20WEB/service/warehous%20-%20C.jpg?updatedAt=1698326189351",
        heading: "Warehouse Service",
        para: "We offer short-term or long-term storage at our facilities. Your goods will be easily retrievable at any given time. We want to meet your exact needs and offer flexible solutions for any customer.",
        button: "Read More"
    },
    {
        slug: "p-2",
        img: "https://ik.imagekit.io/tvvljykxu/ULD%20WEB/service/international-container-cargo-ship-harborfreight-transportation-shipping-ai-generative%20-%20C.jpg?updatedAt=1698326189582",
        heading: "Sea Freight",
        para: "We offer  flexible when routing full containers (20’, 40’ or 40’HQ) and offers lots of possibilities with carriers. Fixed allocations and volume agreements with many carriers enable guaranteed space for your goods.",
        button: "Read More"
    },
    {
        slug: "p-3",
        img: "https://ik.imagekit.io/tvvljykxu/ULD%20WEB/service/critical-freight-services.jpeg?updatedAt=1698322679622",
        heading: "Air Freight",
        para: "Depending on the urgency of your shipment, we can find solutions with the lowest price, the most direct route, or the fastest transport.",
        button: "Read More"
    },
    // {
    //     img: img4,
    //     heading: "Road Freight Forwarding",
    //     para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
    //     button: "Read More"
    // },
    // {
    //     img: img5,
    //     heading: "Road Freight Forwarding",
    //     para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
    //     button: "Read More"
    // },
    // {
    //     img: img1,
    //     heading: "Road Freight Forwarding",
    //     para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
    //     button: "Read More"
    // },
    // {
    //     img: img6,
    //     heading: "Road Freight Forwarding",
    //     para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
    //     button: "Read More"
    // },
    // {
    //     img: img7,
    //     heading: "Road Freight Forwarding",
    //     para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
    //     button: "Read More"
    // },
    // {
    //     img: img8,
    //     heading: "Road Freight Forwarding",
    //     para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
    //     button: "Read More"
    // },
]