import React from 'react'
import {Link} from 'react-router-dom';
// Import Img
// import img1 from '../../../assets/img/common/warehous.jpeg'

const HomeTwoAbout = () => {
    return (
        <>
            <section id="service_about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about_service_img">
                                <img className="rounded" src={"https://ik.imagekit.io/tvvljykxu/ULD%20WEB/home1/warehous.jpeg?updatedAt=1698322560032"} alt="img-about"/>
                            </div>
                        </div>
                        <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                            <div className="about_service_text">
                                <div className="heading-left-border">
                                    <h2>Our Profile and History</h2>
                                </div>
                                <p align="justify">
                                    ULD Group being established in 2008 as a member of IFB Group to support for logistics and distributions. It is a privately owned logistics company by Mr. G P H Glaser. 
                                    We focus on ocean freight and air freight shipments to all major destinations worldwide. Many years of co-operation with our carriers and contractors ensure reliable services, 
                                    space and equipment availability also during peak seasons.
                                </p>
                                <p align="justify">
                                    ULD offer global supply chain management and provide logistics solutions. As an independent company of our size, 
                                    we have to pay special attention to details and to offer tailor-made programs. We offer and arrange the fastest, or the most convenient, routings for our customers’ shipments at competitive rates. 
                                    Working with our own offices and a long-established network of reliable overseas partners since many years we guarantee the same high level of services all over the world.
                                </p>
                                
                            </div>
                        </div>
                    <div className="container">
                    <div className="about_service_text">
                        <div className="heading-left-border">
                            <h2>Our Vision & Values </h2>
                                </div>
                                    <p align="justify">At ULD we are using our professional experience, our flexible, customer-oriented service, and the geographical advantage of Hong Kong, to remain a faithful and reliable partner for future development globally. 
                                        ULD Malaysia was established in the year 2022 and under the member of IFB group, we have over 70 own offices situated in Far East, South East Asia, India Sub-continental and Europe. We are an international logistics 
                                        and freight provider specializing in air and ocean freight services, fully licensed with well-developed network worldwide. Due to rapid business expansion, we are seeking to hire dynamic, high caliber and hands on talents to join our team.
                                    </p>
                                    <Link to="/about" className="btn btn-theme">More About Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                
            </section>
        </>
    )
}

export default HomeTwoAbout
