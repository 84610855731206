import React from 'react'
//ContactInfo Area
const ContactInfo = () => {
    return (
        <>
            <div className="contact_info_wrapper">
                <div className="contact_item">
                    <h5>Our Address</h5>
                    <p>NO. 2 JALAN SUBANG 7, 4, USJ 1, Taman Perindustrian Subang, 47600 Subang Jaya, Selangor.</p>
                </div>
                <div className="contact_item">
                    <h5>Call us on</h5>
                    <p>Office: +03 8081 4641</p>
                    {/* <p>Customer Care: 1800-123-45-6789</p> */}
                </div>
                <div className="contact_item">
                    <h5>Mail Us at</h5>
                    <p>info-my@uldgroup.net.my</p>
                    {/* <p>Wesupportyou@demo.com</p> */}
                </div>
            </div>
        </>
    )
}

export default ContactInfo
